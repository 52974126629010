import React, { useCallback, useEffect, useRef, useState } from "react";
import { defaulted, define, number, object, string } from "superstruct";
import { superstructResolver } from "@hookform/resolvers/superstruct";
import { useForm } from "react-hook-form";
import useTranslations from "../../../hoc/useTranslations";
import Button from "../../../components/Button/Button";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import { Tooltip } from "react-tooltip";
import clsx from "clsx";
import { trackUser } from "../../../helpers/helpers";

import InfoSVG from "../../../assets/icons/info-icon.svg";
import UrlSVG from "../../../assets/icons/url-link.svg";

import "./APICalculator.scss";
import "react-tooltip/dist/react-tooltip.css";

const wordsIn1kTokensValue = 750;
const charactersIn1kTokenValue = 4000;
const lowestCost = 0.0001;

const wordsToTokens = wordsCount => (wordsCount * 1000) / wordsIn1kTokensValue;
const charsToTokens = charsCount => (charsCount * 1000) / charactersIn1kTokenValue;
const toTokens = (count, unit) => (unit === "words" ? wordsToTokens(count) : charsToTokens(count));

const formatCustomErrorMessage = errorMessage => {
	const model = new RegExp(/model="[a-zA-Z-0-9.\s]*/).exec(errorMessage)?.[0];
	const used = new RegExp(/used="[a-zA-Z-0-9.\s]*/).exec(errorMessage)?.[0];
	const maxNumber = new RegExp(/max="[a-zA-Z-0-9.\s]*/).exec(errorMessage)?.[0];

	if (!model || !used || !maxNumber) {
		return undefined;
	}

	return {
		model: model.replace('model="', ""),
		used: used.replace('used="', ""),
		maxNumber: maxNumber.replace('max="', ""),
	};
};

/**
 * @type {import("superstruct").Validator}
 */
const maxTokensValidation = (value, context) => {
	const values = context.branch[0];
	const modelName = values.languageModel;
	const promptUnit = values.queryType;
	const completionUnit = values.answerType;
	const promptSize = values.queryLength;
	const completionSize = values.answerLength;

	if (!modelName) return false; // ask user to pick a model

	if (!promptSize || !completionSize) return true; // don't validate anything if prompt or completion not defined

	const model = languageModelOptions.find(
		model => model.name.replace(/\s/g, "-").toLowerCase() === modelName,
	);

	const promptTokens = toTokens(promptSize, promptUnit);
	const completionTokens = toTokens(completionSize, completionUnit);
	const usedTokens = promptTokens + completionTokens;

	if (usedTokens <= model.maxTokens) {
		return true;
	}

	return {
		message: `model="${model.name}"used="${usedTokens}"max="${model.maxTokens}"`,
		value,
		branch: context.branch,
	};
};

const calculatorSchema = object({
	queryLength: number(),
	queryType: string(),
	queriesCount: defaulted(number(), 1),
	answerLength: number(),
	answerType: string(),
	languageModel: define("properLanguageModel", maxTokensValidation),
});

function calculateAPIUsage(data) {
	const promptSize = Number(data.queryLength),
		promptUnit = data.queryType,
		queriesCount = Number(data.queriesCount),
		completionSize = Number(data.answerLength),
		completionUnit = data.answerType,
		languageModel = data.languageModel;

	const model = languageModelOptions.find(
		model => model.name.replace(/\s/g, "-").toLowerCase() === languageModel,
	);

	if (!model) return;

	const promptCostPer1KTokens = model.price?.prompt ?? model.price;
	const completionCostPer1KTokens = model.price?.completion ?? model.price;

	const promptTokens = toTokens(promptSize, promptUnit);
	const completionTokens = toTokens(completionSize, completionUnit);
	return (
		queriesCount *
		((promptCostPer1KTokens * promptTokens) / 1000 +
			(completionCostPer1KTokens * completionTokens) / 1000)
	);
}

export default function APICalculator() {
	const [result, setResult] = useState("");

	const queriesCountInputRef = useRef(null);
	const resultRef = useRef(null);

	const {
		chatGPT: {
			form: {
				characters,
				words,
				queriesLabel,
				answerLabel,
				numberOfQueriesLabel,
				languageModelLabel,
				calculate,
				estimatedCost,
				generateLink,
				pick_a_model,
				field_error,
				modelLimitExceededError,
			},
		},
	} = useTranslations();

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { isValid, errors },
		watch,
		trigger,
	} = useForm({
		resolver: superstructResolver(calculatorSchema),
		mode: "onBlur",
		defaultValues: {
			queriesCount: 1,
			queryType: "characters",
			answerType: "characters",
			queryLength: null,
			answerLength: null,
			languageModel: null,
		},
		resetOptions: {
			keepDefaultValues: true,
		},
	});

	const queriesCount = watch("queriesCount");

	const handleIncrement = useCallback(() => {
		if (!queriesCount) return setValue("queriesCount", 1);

		setValue("queriesCount", queriesCount + 1);
	}, [queriesCount]);

	const handleDecrement = useCallback(() => {
		if (queriesCount === 1) return;

		if (!queriesCount) return setValue("queriesCount", 1);

		setValue("queriesCount", queriesCount - 1 || 1);
	}, [queriesCount]);

	function scrollToResult() {
		if (resultRef.current) {
			const offset = resultRef.current.offsetTop - 100;
			window.scrollTo({
				top: offset,
				behavior: "smooth",
			});
		}
	}

	let timeoutCleanup = undefined;

	/**
	 *
	 * @param {typeof calculatorSchema.TYPE} data
	 */
	const onSubmit = useCallback(
		data => {
			if (!isValid) return;

			trackUser({
				tagManager: true,
				eventName: "calculate_api_usage",
			});
			const cost = Math.max(calculateAPIUsage(data), lowestCost);
			setResult(
				(cost === lowestCost ? "≈ $" : "$") +
					parseFloat(cost > 5 ? cost.toFixed(2) : cost.toFixed(4)),
			);

			timeoutCleanup = setTimeout(() => {
				scrollToResult();

				timeoutCleanup = undefined;
			}, 10);
		},
		[isValid],
	);

	function generateUrlQuery() {
		const values = getValues();

		if (!values) return;

		const urlQuery = new URLSearchParams(values);

		return window.location.origin + window.location.pathname + "?" + urlQuery.toString();
	}

	const formatPrice = price =>
		price?.prompt
			? `Prompt: $${price.prompt} / 1K<br />Completion: $${price.completion} / 1K`
			: `$${price} / 1K tokens`;

	useEffect(() => {
		if (window.location.search.length >= 5) {
			const urlQuery = new URLSearchParams(window.location.search);

			Array.from(urlQuery.keys()).forEach(key => {
				setValue(key, urlQuery.get(key));
			});

			trigger();

			onSubmit(getValues());
		}

		return () => {
			if (timeoutCleanup) {
				clearTimeout(timeoutCleanup);
			}
		};
	}, [onSubmit]);

	const extractTranslationsFromCustomError = errorData => {
		if (!errorData) return;

		const { maxNumber, model, used } = errorData;

		return modelLimitExceededError
			.replace("{maxTokens}", maxNumber)
			.replace("{model}", model)
			.replace("{usedTokens}", used);
	};

	return (
		<section className="calculator">
			<form className="calculator__form" onSubmit={handleSubmit(onSubmit)}>
				<div className="calculator__form--first">
					<div
						className={clsx("calculator__field", errors.queryLength && "calculator__field--error")}
					>
						<label htmlFor="queries" className="calculator__input-label">
							{queriesLabel}
						</label>
						<div className="calculator__input-group">
							<input
								id="queries"
								type="number"
								placeholder="e.g. 12345"
								className={clsx(
									"calculator__input",
									errors.queryLength && "calculator__input--error",
								)}
								{...register("queryLength", { valueAsNumber: true })}
								data-tooltip-content={errors.queries?.message ? field_error : undefined}
								data-tooltip-id="field-error"
							/>
							<div className="calculator__toggle">
								<label className="calculator__toggle--btn">
									<input type="radio" value="characters" {...register("queryType")} />
									<span className="calculator__toggle--bg">{characters}</span>
								</label>
								<label className="calculator__toggle--btn">
									<input type="radio" value="words" {...register("queryType")} />
									<span className="calculator__toggle--bg">{words}</span>
								</label>
							</div>
						</div>
					</div>
					<div
						className={clsx(
							"calculator__field queries-count",
							errors.queriesCount && "queries-count--error",
						)}
					>
						<label htmlFor="queries-count" className="calculator__input-label">
							{numberOfQueriesLabel}
						</label>
						<div className="calculator__input-group input__count">
							<button type="button" onClick={handleDecrement} disabled={queriesCount === 1}>
								-
							</button>
							<input
								type="number"
								ref={queriesCountInputRef}
								defaultValue={1}
								min={0}
								id="queries-count"
								{...register("queriesCount", { valueAsNumber: true })}
							/>
							<button type="button" onClick={handleIncrement}>
								+
							</button>
						</div>
					</div>
				</div>
				<div
					className={clsx("calculator__field", errors.answerLength && "calculator__field--error")}
				>
					<label htmlFor="answers" className="calculator__input-label">
						{answerLabel}
					</label>
					<div className="calculator__input-group">
						<input
							id="answers"
							type="number"
							placeholder="e.g. 12345"
							className={clsx(
								"calculator__input",
								errors.answerLength && "calculator__input--error",
							)}
							{...register("answerLength", { valueAsNumber: true })}
							data-tooltip-content={errors.answerLength?.message ? field_error : undefined}
							data-tooltip-id="field-error"
						/>
						<div className="calculator__toggle">
							<label className="calculator__toggle--btn">
								<input type="radio" value="characters" {...register("answerType")} />
								<span className="calculator__toggle--bg">{characters}</span>
							</label>
							<label className="calculator__toggle--btn">
								<input type="radio" value="words" {...register("answerType")} />
								<span className="calculator__toggle--bg">{words}</span>
							</label>
						</div>
					</div>
				</div>
				<div
					className={clsx("calculator__field", errors.languageModel && "calculator__field--error")}
				>
					<span className="calculator__input-label">{languageModelLabel}</span>
					<div
						className="calculator__radio-group"
						data-tooltip-content={
							errors.languageModel?.message
								? extractTranslationsFromCustomError(
										formatCustomErrorMessage(errors.languageModel.message),
										//
								  ) || pick_a_model
								: undefined
						}
						data-tooltip-id="field-error"
					>
						{languageModelOptions.map(({ name, tooltip, feature, id, price }) => (
							<div className="calculator__radio-wrapper" key={id + name}>
								<input
									type="radio"
									id={"language-model" + name.replace(/\s/g, "-").toLowerCase()}
									value={name.replace(/\s/g, "-").toLowerCase()}
									{...register("languageModel")}
								/>
								<label
									htmlFor={"language-model" + name.replace(/\s/g, "-").toLowerCase()}
									className="calculator__radio"
								>
									{feature && <span className="calculator__radio-feature">{feature}</span>}
									<span className="calculator__radio-title">
										{name}
										<span
											className="calculator__radio-title--tooltip-container"
											data-tooltip-id="language-model-tooltip"
											data-tooltip-content={tooltip}
										>
											<InfoSVG />
										</span>
									</span>
									<span
										className="calculator__radio-price"
										dangerouslySetInnerHTML={{ __html: formatPrice(price) }}
									></span>
								</label>
							</div>
						))}
						<Tooltip id="language-model-tooltip" place="top" style={{ maxWidth: 300 }} />
					</div>
				</div>
				<Tooltip id="field-error" className="field-error" place="top" style={{ maxWidth: 350 }} />
				<Button type="submit" classNames={["alternative"]}>
					{calculate}
				</Button>
			</form>
			{result && (
				<div className="calculator__result" ref={resultRef}>
					<span className="result__title">{estimatedCost}</span>
					<span className="result__cost">{result}</span>
					<div className="result__url">
						<CopyToClipboard
							text={generateUrlQuery()}
							onCopy={() =>
								trackUser({
									tagManager: true,
									eventName: "copy_calculation_link",
								})
							}
							className="result__url--btn"
						>
							<UrlSVG /> {generateLink}
						</CopyToClipboard>
					</div>
				</div>
			)}
		</section>
	);
}

const languageModelOptions = [
	{
		name: "GPT-3.5-turbo 4K context",
		tooltip: "Optimized for dialogue, the performance on par with Davinci",
		feature: "Cheapest for dialogue",
		maxTokens: 4096,
		price: {
			prompt: 0.0015,
			completion: 0.002,
		},
		id: 0,
	},
	{
		name: "GPT-3.5-turbo 16K context",
		tooltip: "Same capabilities as the standard gpt-3.5-turbo model but with 4 times the context",
		maxTokens: 16384,
		price: {
			prompt: 0.003,
			completion: 0.004,
		},
		id: 1,
	},
	{
		name: "GPT-4 8K context",
		tooltip: "Latest, most advanced model for dialogue, with prompts up to 8K tokens",
		maxTokens: 8192,
		price: {
			prompt: 0.03,
			completion: 0.06,
		},
		id: 2,
	},
	{
		name: "GPT-4 32K context",
		tooltip: "Latest, most advanced model for dialogue, with prompts up to 32K tokens",
		maxTokens: 32768,
		price: {
			prompt: 0.06,
			completion: 0.12,
		},
		id: 3,
	},
	{
		name: "Ada",
		tooltip:
			"Optimized to follow single-turn instructions. Capable of very simple tasks, usually the fastest and lowest cost.",
		feature: "Fastest",
		maxTokens: 2049,
		price: 0.0004,
		id: 4,
	},
	{
		name: "Babbage",
		tooltip:
			"Optimized to follow single-turn instructions. Capable of straightforward tasks, very fast, and lower cost",
		maxTokens: 2049,
		price: 0.0005,
		id: 5,
	},
	{
		name: "Curie",
		tooltip:
			"Optimized to follow single-turn instructions. Very capable, but faster and lower cost than Davinci",
		maxTokens: 2049,
		price: 0.002,
		id: 6,
	},
	{
		name: "Davinci",
		tooltip:
			"Optimized to follow single-turn instructions. Can do any task the other models can do, often with higher quality",
		feature: "Most powerful",
		maxTokens: 2049,
		price: 0.02,
		id: 7,
	},
];
