import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import ChatGPT from "../containers/ChatGPT/ChatGPT";

const ChatGPTPage = ({ pageContext }) => (
	<Layout pageContext={pageContext}>
		<ChatGPT />
	</Layout>
);

ChatGPTPage.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
};

export default ChatGPTPage;
