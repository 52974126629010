import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import useTranslations from "../../hoc/useTranslations";

import "./CopyToClipboard.scss";

export default function CopyToClipboard({ text, className, onCopy, children }) {
	/**
	 * @type {NodeJS.Timeout | undefined}
	 */
	let timeout;

	const [showSuccess, setShowSuccess] = useState(false);
	const [showFailure, setShowFailure] = useState(false);

	useEffect(() => {
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, []);

	/**
	 *
	 * @param {(val: boolean) => void} fn
	 */
	function showMessage(fn) {
		if (timeout) {
			clearTimeout(timeout);
		}

		fn(true);
		timeout = setTimeout(() => fn(false), 2000);
	}

	async function copyToClipboard() {
		if (typeof onCopy === "function") {
			onCopy();
		}

		try {
			await navigator.clipboard.writeText(text);

			showMessage(setShowSuccess);
		} catch {
			setShowFailure(true);
		}
	}

	const {
		copy_to_clipboard: { success, failure },
	} = useTranslations();

	return (
		<>
			{showFailure && (
				<>
					<span className="failure">{failure}</span>
					<p className="failure__text">{text}</p>
				</>
			)}
			{showSuccess && <span className="success">{success}</span>}
			{!showSuccess && !showFailure && (
				<button className={clsx("copy-to-clipboard", className)} onClick={copyToClipboard}>
					{children}
				</button>
			)}
		</>
	);
}

CopyToClipboard.propTypes = {
	children: PropTypes.node.isRequired,
	text: PropTypes.string.isRequired,
	className: PropTypes.string,
	onCopy: PropTypes.func,
};
