import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Button from "../../../components/Button/Button";
import Heading from "../../../components/Heading/Heading";
import useTranslations from "../../../hoc/useTranslations";

import CTAShapeSVG from "../../../assets/icons/ai-cta-shape.svg";

import "./AICta.scss";

export default function AICta({ toggleModal }) {
	const query = useStaticQuery(graphql`
		query {
			bg: file(relativePath: { eq: "ai-calculator-cta-bg.png" }) {
				childImageSharp {
					fluid(maxWidth: 630, quality: 60) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const bgImage = query.bg.childImageSharp.fluid;

	const {
		chatGPT: {
			cta: { title, button, desc1, desc2, imageAlt },
		},
	} = useTranslations();

	return (
		<section className="aicta">
			<div className="aicta__wrapper">
				<CTAShapeSVG className="aicta__shape" />
				<div className="aicta__copy">
					<Heading className="aicta__heading" type={2}>
						{title}
					</Heading>
					<p className="aicta__description">{desc1}</p>
					<Button
						classNames={["aicta__button"]}
						onClick={() => toggleModal("AI_Calculator_CTA", "https://calendly.com/botwiseio/ai")}
					>
						{button}
					</Button>
					<p className="aicta__description" dangerouslySetInnerHTML={{ __html: desc2 }}></p>
				</div>
				<div className="aicta__bg">
					<Img
						fluid={bgImage}
						className="aicta__image"
						alt={imageAlt}
						title={title}
						fadeIn={false}
						loading="eager"
					/>
				</div>
			</div>
		</section>
	);
}

AICta.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};
