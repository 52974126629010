import React from "react";
import PropTypes from "prop-types";
import Heading from "../../components/Heading/Heading";
import useTranslations from "../../hoc/useTranslations";
import SEO from "../../components/seo";
import { PAGE } from "../../helpers/constants";
import APICalculator from "./APICalculator/APICalculator";
import AICta from "./AICta/AICta";

import RingCriclesSVG from "../../assets/icons/ring_circles.svg";

import "./ChatGPT.scss";

export default function ChatGPT({ toggleModal }) {
	const {
		chatGPT: {
			page_title,
			page_subtitle,
			info: { title, details },
		},
	} = useTranslations();

	return (
		<>
			<SEO page={PAGE.CHAT_GPT} />
			<header className="chat-gpt__header">
				<div className="chat-gpt__bg">
					<div className="chat-gpt__bg--icons">
						<RingCriclesSVG />
						<RingCriclesSVG />
						<RingCriclesSVG />
						<RingCriclesSVG />
					</div>
				</div>
				<Heading className="chat-gpt__title" type={1}>
					{page_title}
				</Heading>
				<Heading className="chat-gpt__subtitle" type={2}>
					{page_subtitle}
				</Heading>
			</header>
			<main>
				<APICalculator />
				<section className="chat-gpt__disclaimer">
					<Heading className="chat-gpt__disclaimer-title" type={2}>
						{title}
					</Heading>
					<ul className="chat-gpt__disclaimer-list">
						{details.map(item => (
							<li key={item.toLowerCase().replace(/\s/g, "-").slice(0, 15)}>{item}</li>
						))}
					</ul>
				</section>
				<AICta toggleModal={toggleModal} />
			</main>
		</>
	);
}

ChatGPT.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};
